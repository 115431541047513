<div class="loader-component">
  <div class="loder-section"></div>
  <div class="loder-section"></div>
  <div class="loder-section"></div>
</div>
<section class="sectionHeader" id="header">
  <div class="header-container">
    <div class="container-one">
      <!-- <img src="../../../assets/images/logo/logomark.svg" alt=""> -->
      <div class="section-up">FULL <span>SPECTRUM</span> Development</div>
      <div class="section-one">
        Let <span>us</span> let <span>technology </span> help <span>you.</span>
      </div>
      <div class="section-two">
        We are a full spectrum web and mobile application development company with a keen focus on solving business problems with tech.
      </div>
    </div>
  </div>
  <div class="bottom-container">
    <div class="left-container">
      <i class="fas fa-arrow-down"></i>
      <div class="container-one">
        <!-- Let's dig a bit deeper -->
      </div>
    </div>
    <div class="right-container">
      <i class="fab fa-behance"></i>
      <i class="fab fa-dribbble"></i>
    </div>
  </div>
</section>
<div class="servicesBrief" id="brief">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-4">
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/software product dev.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">Software Product Development</div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
                SmokingDots is mainly a collection of deep tech full-stack developers,
                attentive UI/UX designers, business-focused product managers and automation 
                oriented QA Testers. 
                <br>
                We’ve built many product and service-oriented web+mobile applications for diverse markets and clients. 
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4">
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/reenginering.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">
            Product Reengineering
          </div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
                  We’ve helped several clients update their deserving software products to reap the benefits of 
                  modern-day web & mobile technologies. 
                  <br>It’s never easy updating software systems in real time that sometimes millions of people rely on. 
                  But our work enables our clients to take their businesses to the next stage of growth.  
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4">
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/startup.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">
            Startup as a <br>Service 
          </div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
            This is perhaps what SmokingDots is known best for. We have helped multiple first-time founders as well as experienced, 
            busy tech executives to realise their tech startup dreams.
            <br>We understand the importance of solving monetisable problems with tech while being cognizant of fundraising needs, 
            due diligence and managing growth pains. 
            <br> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
