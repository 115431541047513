<section class="services">
  <div class="services__titleSection">
    <div class="container">
      <div class="services__subTitle">Our Services</div>
      <div class="services__title">
        We allow you to offer the <span> BEST</span> solution for your
        <span>Customer</span>
      </div>
      <div class="services__btnSection">
        <div class="button button--navOutlineBlack">Let's have a talk</div>
      </div>
    </div>
    <div class="container">
      <div class="services__list">
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/software product dev.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">Software Product Development</div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
            We consider Software Product Development as our strongest trait. 
            We  at SmokingDots are experienced full stack developers, product 
            managers, and UX people. Our clients are the most important to us, 
            so we take the time and effort to ensure that we build outstanding 
            products based on our clients needs and exceeding our clients 
            expectations.
          </div>
        </div>
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/reenginering.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">
            Re-engineering all your products
          </div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
            SmokingDots is well  grounded in terms of our Product Re-engineering 
            services. We understand that developing products isn’t easy. Most 
            products developed tend to show flaws, may it be in it’s business case 
            or the product development process itself. We take upon ourselves to 
            aim at ensuring that we create little to no disturbance when enhancing 
            all aspects to your products.
          </div>
        </div>
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/startup.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">Startup as a service</div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
            If you’re looking to develop your startup, we will be able to help 
            you with a thing or two. We consider startups a tech enabled business, 
            and we happen to be experienced with everything tech. We work towards  
            technical due diligence and ensure that development, security and devops 
            work is up to point. 
          </div>
        </div>
        <div class="servicesBrief__card">
          <div class="servicesBrief__icon">
            <img
              class="one"
              src="../../../assets/images/1x/mobiledev.png"
              alt=""
            />
          </div>
          <div class="servicesBrief__title">Mobile enablement</div>
          <div class="servicesBrief__hr"></div>
          <div class="servicesBrief__des">
            Mobile apps are where imagination meets reality, at least that is something 
            that we believe in. So we take pride in having developed  a voice guide app 
            that alters its reading speed real time according to GPS data and your working  
            gate, an AR app for virtual clothes fitting and many more. So share your ideas 
            with us, even though it may not seem like a possibility, we could make it happen. 
            Who knows? Your idea might be just what was needed.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
