<section class="sectionHeader" id="header">
	<div class="header-container">
		<div class="container-one">

			<!-- <img src="../../../assets/images/logo/logomark.svg" alt=""> -->
			<!-- <div class="section-up">FULL <span>SPECTRUM</span> Development</div> -->
			<div class="section-one">
				<img width="200" src="../../../assets/images/logo/logowhite.svg" alt="">
			</div>
			<div class="section-one">
				We gave it our all. <span>Good bye.</span>
			</div>
			<div class="section-two">
				...for now!
			</div>
		</div>
	</div>
</section>