import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {
  projectName: string;
  productType: string;
  myHero: string;
  subTitle: string;
  wahtIsProd: string;
  problem:string;
  solution:string;

  topImg: string;
  middleImg1 : string;
  middleimg2 : string;
  bottomImg: string;

  constructor() { 
    this.projectName = 'Wilson Property website design';
    this.myHero = 'Windstorm';
    this.productType="product design";
    this.subTitle = 'The company specialises in the process of stocking, milling,packaging, exporting and distribution of oats throughout the world, providing a broad variety of products';
    this.wahtIsProd = 'Aussee Oats is an Australian company that specializes in a line of oats based products. The company specialises in the process of stocking, milling, packaging, exporting and distribution of oats throughout the world, providing a broad variety of products.';
    this.problem = 'The main priority was to focus throughout the website on promotingAussee Oats’ vision which is to bring about health and welfare ofits customers. The challenge was to promote the company and itvision to potential B2B customers and eventual B2C customers in aB2B2C setting while creating authenticity and trust in the brand.';
    this.solution = 'The main priority was to focus throughout the website on promotingAussee Oats’ vision which is to bring about health and welfare ofits customers. The challenge was to promote the company and itvision to potential B2B customers and eventual B2C customers in aB2B2C setting while creating authenticity and trust in the brand.';

    this.topImg = '../../../assets/images/case study cover/undp.png';
    this.middleImg1 = '../../../assets/images/case study cover/aussioats/mid1.png';
    this.middleimg2 = '../../../assets/images/aussieoat lap.png';
    this.bottomImg = '../../../assets/images/case study cover/balamu/bottom.png';
  }

  ngOnInit(): void {
  }

}
