<section class="whatWeDo noPadding">
  <div class="container">
    <div class="title--center whatWeDoSection">
      <div class="title__mainText black">What we're known for.</div>
      <!-- <div class="title__subTextNoUpper grey">
        We’re known as the go-to
      </div> -->
      <div class="title__subTextTwoMain grey">
        We’re known as the go-to developers for busy tech executives to 
        develop their side hustles. From Silicon Valley to London, from 
        Colombo to Hong Kong and Sydney, we welcome clients who come 
        from different backgrounds, time zones and bring us diverse 
        problems they want us to solve. We are known for our capabilities 
        in product development, attention to detail, thorough development 
        process and always making time for our clients. We believe that 
        problem solving is always a collaborative engagement and believe 
        our tech to be the enabler; .not the be-all and end-all. We also 
        help individuals, teams and companies with our deep technical 
        knowledge to create MVPs, custom application solutions and on 
        product consultation. 
      </div>
    </div>
    <div class="whatWeDo__cardSection sectionOne">
      <div class="row">
        <div class="col-md-6">
          <div class="title--center">
            <div class="title__mainText black">
              <span>Technologies</span> we use.
            </div>
            <div class="title__subTextTwo">
              Technology is one of the biggest flexes we have. From Javascript 
              to Shopify, we ensure that we’re using the most appropriate 
              technology to help our customers to solve their problems and grow 
              their businesses. 

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="whatWeDo__technologiesCards">
            <div class="whatWeDo__technologiesCard">
              <img src="../../../assets/images/technologies.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="whatWeDo__cardSection sectionTwo">
            <div class="row">
                <div class="col-md-6">
                    <div class="title--center">
                        <div class="title__mainText black">
                            Technologies we use.
                        </div>
                        <div class="title__subTextTwo">
                            We have been proudly partnering with more than 700 forward-thinking companies of all sizes,
                            from startups to world-famous enterprises.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="whatWeDo__technologiesCards">
                        <div class="whatWeDo__technologiesCard">
                            <img src="../../../assets/images/technologies.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- <div class="whatWeDo__cardSection sectionThree">
            <div class="row">
                <div class="col-md-6">
                    <div class="title--left">
                        <div class="title__mainText black">
                            Our Partners.
                        </div>
                        <div class="title__subText">
                            WE'RE MORE THAN A DIGITAL AGENCY
                        </div>
                        <div class="title__subTextTwo">
                            We have been proudly partnering with more than 700 forward-thinking companies of all sizes,
                            from startups to world-famous enterprises.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                </div>
            </div>
        </div> -->
  </div>
</section>
