<section class="letsTalk">
    <div class="container">
        <div class="title--center">
            <div class="title__mainText white">
                Would like to see how we can help ?
            </div>
            <div class="title__subTextTwo">
                Reach out to us. We’re always ready to lend a hand and
                create impactful relationships.
            </div>
        </div>
        <!-- <div class="letsTalk__socialIconList">
            <div class="letsTalk__socialIcon">
                <i class="fab fa-behance"></i>
            </div>
            <div class="letsTalk__socialIcon">
                <i class="fab fa-facebook"></i>
            </div>
            <div class="letsTalk__socialIcon">
                <i class="fab fa-instagram"></i>
            </div>
            <div class="letsTalk__socialIcon">
                <i class="fab fa-linkedin"></i>
            </div>
        </div> -->
        <div class="letsTalk__contactBtn">
            <a href="mailto:hello@smokingdots.com" class="button button--colored">
                Contact Us
            </a>
        </div>
    </div>
</section>