<section class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container">
        <div class="row">
            <div class="col-md vertical-center">
                <div class="ftco-footer-widget left">
                    <div class="logo-item">
                        <img src="../../../assets/images/logo/logomark.svg" alt="">
                    </div>
                    <p>FINDING THE "THING" YOU ARE MISSSING.</p>
                </div>
            </div>
            <div class="col-md">
                <div class="ftco-footer-widget vertical-center">
                    <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                        <!-- <li class="ftco-animate"><a href=""><i class="fab fa-behance"></i></a></li> -->
                        <li class="ftco-animate"><a target="_blank" href="https://www.facebook.com/Smokingdots"> <i
                                    class="fab fa-facebook"></i></a></li>
                        <li class="ftco-animate"><a target="_blank" href="https://www.instagram.com/smokingdots/"><i
                                    class="fab fa-instagram"></i></a></li>
                        <li class="ftco-animate"><a target="_blank"
                                href="https://www.linkedin.com/company/smoking-dots"><i class="fab fa-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md">
                <div class="ftco-footer-widget mb-4 vertical-center">
                    <h2 class="ftco-heading-2">Have Questions?</h2>
                    <div class="block-23">
                        <ul>
                            <li class="contactInfo"><a href="tel:+94775092660"><i class="fa fa-phone icon"></i><span
                                        class="text">+94
                                        77 509 2660</span></a></li>
                            <li class="contactInfo"><a href="mailto:hello@smokingdots.com"><i
                                        class="fa fa-envelope icon"></i><span
                                        class="text">hello@smokingdots.com</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>