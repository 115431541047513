import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wilsons-case-study',
  templateUrl: './wilsons-case-study.component.html',
  styleUrls: ['./wilsons-case-study.component.scss']
})
export class WilsonsCaseStudyComponent implements OnInit {
  projectName: string;
  productType: string;
  myHero: string;
  subTitle: string;
  wahtIsProd: string;
  problem:string;
  solution:string;

  topImg: string;
  middleImg1 : string;
  middleimg2 : string;
  bottomImg: string;

  constructor() { 
    this.projectName = 'Wilson Property Services';
    this.myHero = 'Windstorm';
    this.productType="Website development";
    this.subTitle = 'Wilson Property Services is a service and facility management service provider that assists all matters, within wide ranges of issues presented. ';
    this.wahtIsProd = 'Wilson Property Services is a service and facility management service provider that assists all matters, within wide ranges of issues presented. Specializing in all aspects with standard and always being on the lookout by creating solutions for conflicts faced by clients with inventive and ingenious technology is what is strived for. Keeping in mind the environment, and all policies taken in order to provide services and products have been done in a manner not to harm or danger the surrounding.';
    this.problem = 'Branding the company, and services provided with high end quality assistance for reasonable prices was what was required to be promoted.The requirements that were requested by the company were to be met along with being able to develop a presentable website that would attract their current and potential customers. ';
    this.solution = 'We selected icons to display the variou products and services to show stability and continuity.Colours being utilized have been done in a manner to showcase the original branding as well as for easy navigation, making sure that all customers that visit the website have a delightful and informative experience.';

    this.topImg = '../../../assets/images/case study cover/wilsons/top.png';
    this.middleImg1 = '../../../assets/images/case study cover/wilsons/mid1.png';
    this.middleimg2 = '../../../assets/images/aussieoat lap.png';
    this.bottomImg = '../../../assets/images/case study cover/wilsons/bottom.png';
  }

  ngOnInit(): void {
  }

}
