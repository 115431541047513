<section class="aboutUs">
  <div class="container">
    <div class="title--left">
      <div class="title__mainText">About.</div>
      <div class="title__subText">
        We are a company discovering original solutions for new and evolving businesses 
        of the current day. We utilise cutting edge technology, full-stack development, 
        and great DevOps in order to solve complex business problems. We do all this across Digital 
        Transformation, User Experience Development, design & crafting and continuous 
        solution availability. 
      </div>
    </div>

    <div class="aboutUs__threeSection">
      <div class="aboutUs__item">
        <div class="aboutUs__itemTitle">Who We Are</div>
        <div class="aboutUs__itemDes">
          SmokingDots is a collection of passionate young people, mentored by 
          experienced tech and business executives, who want to solve business 
          problems large and small with the use of tech. 
              <!-- <br> -->
         We believe that business problems transcend national boundaries and 
          tech enables people and businesses alike to solve problems that 
          they’re passionate about. 
            <!-- <br> -->
          Our job is to help you help solve those problems. If we’re to say so 
          ourselves, we’re actually quite good at it. 

        </div>
      </div>
      <div class="aboutUs__item">
        <div class="aboutUs__itemTitle">Our Philosophy</div>
        <div class="aboutUs__itemDes">
          We’re driven by a very simple philosophy. We take care of our people and they, 
          in turn, take care of our people and expand our business, together. 
              <!-- <br> -->
          Our people: Our clients and our team.
                <!-- <br> -->
          We always believe that we can do better. So we spend a lot of time improving 
          our work; our relationship and goodwill with our clients; and improving our 
          people, both professionally and personally. 

        </div>
      </div>
      <div class="aboutUs__item">
        <div class="aboutUs__itemTitle">How We Work</div>
        <div class="aboutUs__itemDes">
          SmokingDots believes in its process. Regardless of whether the client we work 
          with is a Silicon Valley tech executive or a first-time entrepreneur, we take 
          them through a product discovery journey weighing various areas of pros and 
          cons before we design a single element or write a single line of code.
              <!-- <br> -->
          Some say that this is overkill. But it has worked for our clients and our 
          team time and time again. 

        </div>
      </div>
    </div>
  </div>
  <div class="aboutUs__logoMark">
    <img src="../../../assets/images/logo/logomark.svg" alt="" />
  </div>
</section>
