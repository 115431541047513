import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
// import { LandingPageComponent } from './landing-page/landing-page.component';
// import { AboutUsLandingComponent } from './about-us/about-us.component';
// import { CaseStudiesComponent } from './case-studies/case-studies.component';
// import { CaseStudyComponent } from './case-studies/case-study/case-study.component';
// import { ServicesComponent } from './services/services.component';
// import { BalamuCaseStudyComponent } from './balamu-case-study/balamu-case-study.component';
// import { UndpCaseStudyComponent } from './undp-case-study/undp-case-study.component';
// import { AussioatsCaseStudyComponent } from './aussioats-case-study/aussioats-case-study.component';
// import { WilsonsCaseStudyComponent } from './wilsons-case-study/wilsons-case-study.component';
// import { LooknBookCaseStudyComponent } from './lookn-book-case-study/lookn-book-case-study.component';
const routes: Routes = [
	// { path: '', redirectTo: '/home', pathMatch: 'full' },

	// { path: 'home', component: LandingPageComponent },
	// { path: 'aboutUs', component: AboutUsLandingComponent },
	// { path: 'caseStudies', component: CaseStudiesComponent },
	// { path: 'caseStudy', component: CaseStudyComponent },
	// { path: 'services', component: ServicesComponent },
	// { path: 'undp', component: UndpCaseStudyComponent },
	// { path: 'aussioats', component: AussioatsCaseStudyComponent },
	// { path: 'wilsons', component: WilsonsCaseStudyComponent },
	// { path: 'looknbook', component: LooknBookCaseStudyComponent },
	// { path: '**', component: LandingPageComponent },
	{ path: '**', redirectTo: '/' },
]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }