import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-partnerships',
  templateUrl: './our-partnerships.component.html',
  styleUrls: ['./our-partnerships.component.scss']
})
export class OurPartnershipsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
