import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lookn-book-case-study',
  templateUrl: './lookn-book-case-study.component.html',
  styleUrls: ['./lookn-book-case-study.component.scss']
})
export class LooknBookCaseStudyComponent implements OnInit {
  projectName: string;
  productType: string;
  myHero: string;
  subTitle: string;
  wahtIsProd: string;
  problem:string;
  solution:string;

  topImg: string;
  middleImg1 : string;
  middleimg2 : string;
  bottomImg: string;

  constructor() { 
    this.projectName = 'Colombo Fashion Week (Look & Book application)';
    this.myHero = 'Windstorm';
    this.productType="Product Design";
    this.subTitle = 'Colombo Fashion Week is a project of development looking into continuous changes to be made and applied into the Sri Lankan Fashion Industry. ';
    this.wahtIsProd = 'Colombo Fashion Week is a project of development looking into continuous changes to be made and applied into the Sri Lankan Fashion Industry. Creating an atmosphere that helps nurture the very best of Sri Lankan fashion is what it’s being aimed at. Being aware of the want that is being displayed during the show for the designs and outfits, it was thought that an Application would be a brilliant way of assisting all those that would like a little more.';
    this.problem = 'Creating a customized system that would make it easier for audiences to be able to access all details of clothing while it is being displayed on the ramp at that precise moment, such as all the features and the type of style, making it easier to make a purchasing decision. ';
    this.solution = 'We developed an application called the “Look and Book” to help audiences book and purchase the outfits that were being presented while the fashion show was going on, with the added description of the texture,style,color,design and designer, furthermore assisting the audience with making a well informed decision.';

    this.topImg = '../../../assets/images/case study cover/lookandbook/top.png';
    this.middleImg1 = '../../../assets/images/case study cover/lookandbook/middle.png';
    this.middleimg2 = '../../../assets/images/aussieoat lap.png';
    this.bottomImg = '../../../assets/images/case study cover/balamu/bottom.png';
  }

  ngOnInit(): void {
  }

}
