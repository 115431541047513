<section class="testimonials">
    <div class="container">
        <div class="title--center">
            <div class="title__mainText black">
                Testimonials.
            </div>
            <div class="title__subText grey">
                A FEW WORDS OUR AMAZING CUSTOMERS HAD TO SAY ABOUT US.
            </div>
        </div>


        <div class="testimonials__list">

            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="testimonialsCard">
                        <div class="testimonialsCard__content">
                            "Building products has never been easier as it has been the case
                            with SmokingDots. Not only did they make sure to hear us out and
                            take our opinions into consideration, but they also made sure to
                            put their spin and expertise in making sure that our products are
                            perfect and beyond even our expectations.”
                        </div>
                        <div class="testimonialsCard__customer">
                            <div class="testimonialsCard__userImage">
                                <img src="../../../assets/images/workedWith/1x/Asset 22.png" alt="">
                            </div>
                            <div>
                                <div class="testimonialsCard__userName">
                                    Timothy Wijeysooriya
                                </div>
                                <div class="testimonialsCard__userCompany">
                                    Chief Executive Officer, <br> APEX Managed Solutions
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="testimonialsCard">
                        <div class="testimonialsCard__content">
                            "The team at SmokingDots worked on the Sri Lanka Tourism COVID Registration system which was
                            supported by UNDP and it was
                            great to work with them. The team was agile and very responsive during the project and it
                            was very easy to work with
                            them. The team also understood the requirements very fast and were able to propose possible
                            solutions. It was definitely
                            a pleasure to work with Avindu and the team”
                        </div>
                        <div class="testimonialsCard__customer">
                            <div class="testimonialsCard__userImage">
                                <img src="../../../assets/images/workedWith/1x/Asset 20.png" alt="">
                            </div>
                            <div>
                                <div class="testimonialsCard__userName">
                                    Ahamed Nishadh
                                </div>
                                <div class="testimonialsCard__userCompany">
                                    Tech for Development Lead, <br> UNDP Sri Lanka / Citra Social Innovation Lab
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="testimonialsCard">
                        <div class="testimonialsCard__content">
                            "SmokingDots helped us add further valued to our clients with their in-depth
                            software development capabilities. They also have a thorough business
                            understanding, leveraging technology enablement assisted by good business+technology
                            integrations in order to provide better outcomes for our clients. They are quick,
                            driven by good processes and overall, great to work with.”
                        </div>
                        <div class="testimonialsCard__customer">
                            <div class="testimonialsCard__userImage">
                                <img src="../../../assets/images/workedWith/1x/Asset 18.png" alt="">
                            </div>
                            <div>
                                <div class="testimonialsCard__userName">
                                    Sonal Jayawickrama
                                </div>
                                <div class="testimonialsCard__userCompany">
                                    Chief Executive Officer, <br> Morpheus Digital Services
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </owl-carousel-o>
        </div>
    </div>
</section>