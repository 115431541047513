import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aussioats-case-study',
  templateUrl: './aussioats-case-study.component.html',
  styleUrls: ['./aussioats-case-study.component.scss']
})
export class AussioatsCaseStudyComponent implements OnInit {
  projectName: string;
  productType: string;
  myHero: string;
  subTitle: string;
  wahtIsProd: string;
  problem:string;
  solution:string;

  topImg: string;
  middleImg1 : string;
  middleimg2 : string;
  bottomImg: string;

  constructor() { 
    this.projectName = 'Aussee oats';
    this.myHero = 'Windstorm';
    this.productType="Website development";
    this.subTitle = 'Aussee oats is an Australian based company that specializes in a line of products that uses oats, Furthermore aspiring to be the lead in the process of gathering, milling,packaging,exporting and distribution of oats throughout the world';
    this.wahtIsProd = 'Aussee oats is an Australian based company that specializes in a line of products that uses oats, Furthermore aspiring to be the lead in the process of gathering, milling,packaging,exporting and distribution of oats throughout the world, providing a broad variety of products. Aussee Oats takes steps in making certain that everything required takes place in order to provide reliable and quality products for all their customers, representing what it is that they believe in, health and well- being.';
    this.problem = 'The main priority was to make sure that the focus throughout the website was to create and promote what their belief system represented , which is to bring about health and welfare. Furthermore, demonstrating the services that they render along with the products that are being manufactured, are laid out accordingly.';
    this.solution = 'Since colour plays quite a role in how an article may be perceived, we decided that white would be used throughout the website representing purity and health as per what their belief system is built on,Along with that orange too was prominently placed as it embodies the brand itself.';

    this.topImg = '../../../assets/images/case study cover/aussioats/top.png';
    this.middleImg1 = '../../../assets/images/case study cover/aussioats/mid1.png';
    this.middleimg2 = '../../../assets/images/aussieoat lap.png';
    this.bottomImg = '../../../assets/images/case study cover/aussioats/bottom.png';
  }

  ngOnInit(): void {
  }

}
