<section class="sectionFive">
    <div class="second-container">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="imageContainer">
                        <img class="image-bottom" src="./assets/images/img.png" alt="">
                    </div>
                </div>
                <div class="col-md-6 vertical--Center">
                    <div class="container-one">
                        The
                    </div>
                    <div class="container-two">
                        Heroic influencers for heroic brands.
                    </div>
                    <div class="container-three">
                        We’ve connected heroic brands and publishers with the right gaming talent for wildly
                        creative, record-breaking promotions and endorsements across the video game industry.
                        Gaming is about more than games and we’re here to prove it.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>