import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HeaderComponent } from './landing-page/header/header.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { FooterComponent } from './common/footer/footer.component';
import { WahtWeDoComponent } from './landing-page/waht-we-do/waht-we-do.component';
import { AppRoutingModule } from './app-routing.module';
import { ServicesComponent } from './services/services.component'; // CLI imports 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OurClientsComponent } from './landing-page/our-clients/our-clients.component';
import { ProjectsOneComponent } from './landing-page/projects-one/projects-one.component';
import { OurPartnershipsComponent } from './landing-page/our-partnerships/our-partnerships.component';
import { TestimonialsComponent } from './landing-page/testimonials/testimonials.component';
import { LetsTalkComponent } from './landing-page/lets-talk/lets-talk.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { WhatWeDoComponent } from './landing-page/what-we-do/what-we-do.component';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { CaseStudyComponent } from './case-studies/case-study/case-study.component';
import { BalamuCaseStudyComponent } from './balamu-case-study/balamu-case-study.component';
import { AussioatsCaseStudyComponent } from './aussioats-case-study/aussioats-case-study.component';
import { WilsonsCaseStudyComponent } from './wilsons-case-study/wilsons-case-study.component';
import { UndpCaseStudyComponent } from './undp-case-study/undp-case-study.component';
import { LooknBookCaseStudyComponent } from './lookn-book-case-study/lookn-book-case-study.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';
@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    WahtWeDoComponent,
    ServicesComponent,
    OurClientsComponent,
    ProjectsOneComponent,
    OurPartnershipsComponent,
    TestimonialsComponent,
    LetsTalkComponent,
    WhatWeDoComponent,
    CaseStudiesComponent,
    CaseStudyComponent,
    BalamuCaseStudyComponent,
    AussioatsCaseStudyComponent,
    WilsonsCaseStudyComponent,
    UndpCaseStudyComponent,
    LooknBookCaseStudyComponent,
    GoodbyeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
