import { Component, OnInit } from '@angular/core';
import {gsap,TweenMax,TimelineLite,Power3} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const tl = gsap.timeline();
    gsap.registerPlugin(ScrollTrigger)
    tl.to ('.loder-section', {
      height:0,
      ease:'expo.inOut',
      delay:1,
      stagger: {
        amount:1
      }
    })    
    .from('.container-one',{
      x:-1000,
      opacity:0,
      delay:0.0,
    })
    .from('.servicesBrief__card',{
      y:100,
      opacity:0,
      ease: 'power4.out',
      delay:0.3,
      skewY:0.5,
      stagger: {
        amount:0.5
      }
    })
    .to('.loader-component',{
      css:{display:'none'}
    })

    let scrollTime = gsap.timeline({
      scrollTrigger: {
        trigger: '.sectionHeader',
        once:false,
        start: "top top 20px",
        end:"center top",
        markers: false,
        toggleActions: "play pause pause reverse",
        onEnter: () => this.enter(),
        onLeaveBack: () => this.leave(),
      }, defaults: { ease: "power1.inOut" }
    })
  }
  enter() {
    let enter = document.getElementById('brief');
    enter.scrollIntoView({behavior:"smooth",block: 'start'});
  }
  leave() {
    console.log('asda');
    let exit = document.getElementById('header');
    exit.scrollIntoView({behavior:"smooth"});
  }
}
