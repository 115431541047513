import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-balamu-case-study',
  templateUrl: './balamu-case-study.component.html',
  styleUrls: ['./balamu-case-study.component.scss']
})
export class BalamuCaseStudyComponent implements OnInit {
  projectName: string;
  productType: string;
  myHero: string;
  subTitle: string;
  wahtIsProd: string;
  problem:string;
  solution:string;

  topImg: string;
  middleImg1 : string;
  middleimg2 : string;
  bottomImg: string;

  constructor() { 
    this.projectName = 'BALAMU application';
    this.myHero = 'Windstorm';
    this.productType="product design";
    this.subTitle = 'Esse Consultancy is a company that is based in the UK , and they bring forth a new and reviving way of assisting all tourists and travelers in Sri Lanka,by introducing BA.LA.MU';
    this.wahtIsProd = 'Esse Consultancy is a company that is based in the UK , and they bring forth a new and reviving way of assisting all tourists and travelers in Sri Lanka,by introducing BA.LA.MU - easily translating to “let’s go see”. This Application is to help tourists and travelers with navigation,stirring them into the right places for Adventure and History. As one of the most visited locations in the world, this Application is to help with informative and accurate details.';
    this.problem = 'Developing and creating an application that would assist as a virtual tour guide was what we were faced with,Making certain that it could be easily accessed as well as convenient. Also being important that this Application could be easily understood for all, in making their journey effortless and memorable.';
    this.solution = 'Building a systemized map that assists in navigation, with easy to find landmarks was one of the most important features, including high quality audio guide for when on the move, with step by step instructions, descriptions, and historical facts. Furthermore creating a customized feature of Visual References for additional assistance.';

    this.topImg = '../../../assets/images/case study cover/undp.png';
    this.middleImg1 = '../../../assets/images/case study cover/aussioats/mid1.png';
    this.middleimg2 = '../../../assets/images/aussieoat lap.png';
    this.bottomImg = '../../../assets/images/case study cover/balamu/bottom.png';
  }

  ngOnInit(): void {
  }

}
