import { Component, OnInit } from '@angular/core';
import { gsap, TweenMax, TimelineLite, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router'; // CLI imports router
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navColor: boolean = false;
  menu: boolean = true;
  whiteBackgroundMenu: boolean = false
  constructor(private router: Router) { }

  ngOnInit() {



    // while (this.router.url !== '/caseStudies'){
    //   console.log(this.router.url);
    //   this.whiteBackgroundMenu = true;
    // }

    gsap.registerPlugin(ScrollTrigger)
    let t2 = gsap.timeline()
    t2.from('.nav-item', {
      y: -100, delay: 2.5, ease: 'power4.out',
      stagger: {
        amount: 0.5
      }
    })
      .to('.navbarCust', {
        delay: 1,
        scrollTrigger: {
          trigger: '.navbarCust',
          start: "bottom top",
          end: "top top",
          // markers: true,
          onEnter: () => this.myfunction(),
          onLeaveBack: () => this.myfunction(),

        }
      })
  }

  myfunction() {
    this.navColor = !this.navColor;
    console.log('asdas');
  }

  toggleMenu() {

    // this.menu = !this.menu;
    let t3 = gsap.timeline()
    t3.to('.navbarCust__overlay', { css: { display: 'block' } })
      .from('.navbarCust__overlay', { x: 600, opacity: 0.5 })
      .to('.navbarCust__overlay', { x: 0, opacity: 1 })

  }
  toggleMenuOff() {
    // this.menu = !this.menu;
    console.log('testing');
    let t4 = gsap.timeline()
    t4.to('.navbarCust__overlay', { x: 600 })
      .to('.navbarCust__overlay', { css: { display: 'none' } })
      .to('.navbarCust__overlay', { x: 0, opacity: 1 })


  }
}
