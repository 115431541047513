import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {
  caseStudies =[
    {
      image:"../../assets/images/case study cover/lookandbook/top.png",
      title:" Colombo Fashion Week",
      description:" Colombo Fashion Week is a project of development looking into continuous changes to be made and applied into the Sri Lankan Fashion Industry. ",
      features:[ "Product Design", "UX Strategy"," Progressive Web Application"],
      link:"/looknbook"
    },
    {
      image:"../../assets/images/case study cover/aussioats/top.png",
      title:"Aussee oats",
      description:"Aussee oats is an Australian based company that specializes in a line of products that uses oats, Furthermore aspiring to be the lead in the process of gathering, milling,packaging,exporting and distribution of oats throughout the world",
      features:[ "Website development", "Experience mapping"],
      link:"/aussioats"
    },
    {
      image:"../../assets/images/case study cover/wilsons/top.png",
      title:"Wilson Property Services",
      description:"Wilson Property Services is a service and facility management service provider that assists all matters, within wide ranges of issues presented. ",
      features:[ "Website development", "Experience mapping"],
      link:"/wilsons"

    },
    {
      image:"../../assets/images/case study cover/undp/top.png",
      title:"UNDP Visa travel application",
      description:"The outbreak of Covid-19 hindred travelling for all those travel lovers along with those visiting their families. With travelling now restored, UNDP took to point that a proper system be made in order to track all those visiting Sri Lanka.",
      features:[ "product design", "UX Strategy","User Experience mapping","Mobile Application Development"],
      link:"/undp"

    }

  ]
  constructor() { }

  ngOnInit(): void {
  }

  
}
