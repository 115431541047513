import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-undp-case-study',
  templateUrl: './undp-case-study.component.html',
  styleUrls: ['./undp-case-study.component.scss']
})
export class UndpCaseStudyComponent implements OnInit {
  projectName: string;
  productType: string;
  myHero: string;
  subTitle: string;
  wahtIsProd: string;
  problem:string;
  solution:string;

  topImg: string;
  middleImg1 : string;
  middleimg2 : string;
  bottomImg: string;

  constructor() { 
    this.projectName = 'UNDP Visa travel application';
    this.myHero = 'Windstorm';
    this.productType="Product design";
    this.subTitle = 'A mobile Application designed, to help with the Visa process, insurance, contact tracings and more.';
    this.wahtIsProd = 'The outbreak of Covid-19 hindred travelling for all those travel lovers along with those visiting their families. With travelling now restored, UNDP took to point that a proper system be made in order to track all those visiting Sri Lanka.';
    this.problem = 'However, this is to be done with a set of rules and regulations that must be followed in order to visit Sri Lanka making sure of the safety and well being of all residing as well as those that would be visiting,in order to enjoy and experience fulfilling adventures.';
    this.solution = 'A mobile Application designed, for this very purpose,to help with the Visa process, insurance, contact tracings and more. Downloading this Application is mandatory. This application has been developed and created in order to assist all those visiting Sri Lanka. Individuals will be notified of all current occurrences and changes.';

    this.topImg = '../../../assets/images/case study cover/undp/top.png';
    this.middleImg1 = '../../../assets/images/case study cover/undp/middle.png';
    this.middleimg2 = '../../../assets/images/aussieoat lap.png';
    this.bottomImg = '../../../assets/images/case study cover/undp/bottom.png';
  }

  ngOnInit(): void {
  }

}
