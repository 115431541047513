<section class="ourPartnership">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="title--left partners">
                    <div class="title__mainText black">
                        Our Partners.
                    </div>
                    <div class="title__subText grey">
                        <!-- OUR CLIENTS ARE MORE THAN JUST CUSTOMERS -->
                    </div>
                    <div class="title__subTextTwo">
                        Our partners are our pillars of success. We’ve come through a hard
                        and arduous journey. It’s our partners on whose shoulders that we
                        stood to bring SmokingDots to where it is today. We are much humbled
                        that they choose to trust and believe in us.

                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="ourPartnership__imageSection">
                    <div class="ourPartnership__logoItem">
                        <img src="../../../assets/images/workedWith/1x/Asset 18.png" alt="">
                    </div>
                    <div class="ourPartnership__logoItem">
                        <img src="../../../assets/images/workedWith/1x/Asset 19.png" alt="">
                    </div>
                    <div class="ourPartnership__logoItem">
                        <img src="../../../assets/images/workedWith/1x/Asset 22.png" alt="">
                    </div>
                    <div class="ourPartnership__logoItem">
                        <img src="../../../assets/images/workedWith/1x/Asset 17.png" alt="">
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>