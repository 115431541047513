import { Component, OnInit } from '@angular/core';
import {gsap,TweenMax,TimelineLite,Power3} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-lets-talk',
  templateUrl: './lets-talk.component.html',
  styleUrls: ['./lets-talk.component.scss']
})
export class LetsTalkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // gsap.registerPlugin(ScrollTrigger)
    // let letsTalk = gsap.timeline()
    // letsTalk.from('.letsTalk .title--center',{x:20,opacity:0,stagger: {amount: 0.5},delay: 1,
    // scrollTrigger: {
    //   trigger: '.letsTalk',
    //   start: "top top",
    //   markers: false,
    // }}).from('.letsTalk__socialIconList .letsTalk__socialIcon',{x:20,opacity:0,stagger: {amount: 0.5}})
  }
}
