import { Component, OnInit } from '@angular/core';
import { gsap, TweenMax, TimelineLite, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

@Component({
  selector: 'app-waht-we-do',
  templateUrl: './waht-we-do.component.html',
  styleUrls: ['./waht-we-do.component.scss']
})
export class WahtWeDoComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    // gsap.registerPlugin(ScrollTrigger)
    // let t4 = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.whatWeDo',
    //     start:'top top',
    //     end:'bottom bottom',
    //     markers: false,
    //     scrub: 2,
    //     pin: true,
    //     toggleActions: "play pause pause reverse"
    //   }, defaults: { ease: "power1.inOut" }
    // })
    // t4.to('.sectionTwo', { y: -460,opacity:1, ease: 'power4.inOut' })
    // t4.to('.sectionThree', { y: -460,opacity:1, ease: 'power4.inOut',delay:1 })
    // t4.to('.sectionThree', { y: -920,opacity:1, ease: 'power4.out' })
  }

}
