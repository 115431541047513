import { Component, OnInit } from '@angular/core';
import { gsap, TweenMax, TimelineLite, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],

})
export class LandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    gsap.registerPlugin(ScrollTrigger)
    let t2 = gsap.timeline({
      scrollTrigger: {
        trigger: '.title--left',
        once:false,
        start: "top 90%",
        markers: false,
      }, defaults: { ease: "power1.inOut" }
    })
    t2.from('.title--left', { y: 100, opacity: 0, ease: 'power4.out', delay: 0.3, skewY: 0.5 })
    t2.from('.title--center', { y: 100, opacity: 0, ease: 'power4.out', delay: 0.3, skewY: 0.5 })
    t2.from('.aboutUs__item', { y: 100, opacity: 0, ease: 'power4.out', delay: 0, stagger: { amount: 0.3 } })

    // let t3 = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.ourPartnership',
    //     once:false,
    //     start: "top 90%",
    //     markers: true,
    //   }, defaults: { ease: "power1.inOut" }
    // })
    // t3.from('.partners', { y: 100, opacity: 0.8, ease: 'power4.out', skewY: 0.5 })
  }
}
