<div id="main" class="main">
    <section class="productSectionHeader">
      <div class="second-container">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="title--left partners">
                <div class="title__subText">{{productType}}</div>
                <div class="title__mainText">
                  {{projectName}}
                </div>
                <div class="title__subTextTwo">
                  {{subTitle}}
                </div>
                <div class="caseStudyCard__bullets">
                  <div class="caseStudyCard__bulletPoint">Product Design</div>
                  <div class="caseStudyCard__bulletPoint">UX Strategy</div>
                  <div class="caseStudyCard__bulletPoint">Mobile Application Development</div>
                  <div class="caseStudyCard__bulletPoint">User experience mapping</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 vertical--Center">
              <div class="imgae-container">
                <img src="{{topImg}}" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="productSectionTwo">
      <div class="second-container">
        <div class="container">
          <div class="container-two">What is Aussee oats</div>
          <div class="container-three">
            {{wahtIsProd}}
  
          </div>
          <div class="imgae-container">
            <img src="{{middleImg1}}" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="productSectionThree">
      <div class="second-container">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="container-two">Problem</div>
              <div class="container-three">
                {{problem}}
              </div>
            </div>
            <div class="col-md-6">
              <div class="container-two">Solution</div>
              <div class="container-three">
                {{solution}}
              </div>
            </div>
          </div>
  
          <!-- <div class="imgae-container">
            <img src="{{middleimg2}}" alt="" />
          </div> -->
        </div>
      </div>
    </section>
    <section class="productSectionFour">
      <img src="{{bottomImg}}" alt="" />
    </section>
  </div>
  