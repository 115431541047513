<section class="caseStudy">
  <div class="caseStudy__titleSection">
    <div class="container">
      <div class="caseStudy__subTitle">Our Projects</div>
      <div class="caseStudy__title">
        We just might be the design ingredient <span> missing</span> from your
        <span>digital</span> product
      </div>
      <div class="caseStudy__btnSection">
        <div class="button button--navOutlineBlack">Let's have a talk</div>
      </div>
    </div>
    <div class="container">
      <div class="caseStudy__cardList">
        <div class="caseStudyCard" *ngFor="let casestudy of caseStudies">
          <div class="caseStudyCard__image">
            <img src="{{ casestudy.image }}" alt="" />
          </div>
          <div class="caseStudyCard__Logo"></div>
          <div class="caseStudyCard__title">
            {{ casestudy.title }}
          </div>
          <div class="caseStudyCard__subtitle">
            {{ casestudy.description }}
          </div>
          <div class="caseStudyCard__bullets">
            <div class="caseStudyCard__bulletPoint" *ngFor="let feature of casestudy.features"> {{ feature }}</div>
            <!-- <div class="caseStudyCard__bulletPoint">{{ casestudy.features[1] }}</div>
            <div class="caseStudyCard__bulletPoint">
                {{ casestudy.features[2] }}
            </div>
            <div class="caseStudyCard__bulletPoint">
                {{ casestudy.features[3] }}
            </div> -->
          </div>
          <a
            class="button button--navOutline left"
            routerLink="{{casestudy.link}}"
          >
            Read case study
          </a>
        </div>
        <!-- <div class="caseStudyCard">
          <div class="caseStudyCard__image">
            <img src="../../assets/images/undp.png" alt="" />
          </div>
          <div class="caseStudyCard__Logo"></div>
          <div class="caseStudyCard__title">
            In order to ease the process immigration
          </div>
          <div class="caseStudyCard__subtitle">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
          <div class="caseStudyCard__bullets">
            <div class="caseStudyCard__bullets">
              <div class="caseStudyCard__bulletPoint">Product Design</div>
              <div class="caseStudyCard__bulletPoint">UX Strategy</div>
              <div class="caseStudyCard__bulletPoint">
                User experience mapping
              </div>
              <div class="caseStudyCard__bulletPoint">
                Mobile Application Development
              </div>
            </div>
          </div>
          <div class="button button--navOutline left">Read case study</div>
        </div> -->
      </div>
    </div>
  </div>
</section>
