<nav class="navbarCust white">
    <div class="navbarCust__overlay">
        <div class="container">
                <i (click)="toggleMenuOff()" class="fas fa-times xIcon"></i>
                <div class="navbarCust__Overlayhref ">
                    <a (click)="toggleMenuOff()" class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/home']" routerLinkActive="active-link">Home</a>
                    <a (click)="toggleMenuOff()" class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/caseStudies']" routerLinkActive="active-link">Projects</a>
                    <a (click)="toggleMenuOff()" class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/services']" routerLinkActive="active-link">Services</a>
                    <a (click)="toggleMenuOff()" class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/aboutUs']" routerLinkActive="active-link">About us</a>
                    <div class="navbarCust__OverLaycontactUs nav-item">
                        <a href="mailto:avindu@smokingdots.com" class="button button--navMobile btnTextColor">
                            Hit us up
                        </a>
                    </div>
                </div>
        </div>
        </div>

    <div class="navbarCust__left" >
        <div class="navbarCust__logoContainer nav-item" [routerLink]="['/home']">
            <img src="../../../assets/images/logo/logowhite.svg" alt="">
        </div>
    </div>

    <div class="navbarCust__right">
        <div class="navbarCust__menuIcon">
            <i (click)="toggleMenu()" [ngClass]="navColor ? 'white' : 'black'" class="fas fa-bars"></i>
        </div>
        <div class="navbarCust__href ">
            <a  class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/caseStudies']" routerLinkActive="active-link">Projects</a>
            <a  class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/services']" routerLinkActive="active-link">Services</a>
            <a  class="nav-item" href="" [ngClass]="navColor ? 'white' : 'black'" [routerLink]="['/aboutUs']" routerLinkActive="active-link">About us</a>
            <div class="navbarCust__contactUs nav-item">
                <a href="mailto:avindu@smokingdots.com" class="button button--navOutline ">
                    Hit us up
                </a>
            </div>
        </div>

    </div>
</nav>